import React, { useState, useEffect } from 'react';
import "./App.css";
import Board from "./components/Board";
import Keypad from "./components/Keypad";
import Btn from "./components/Btn";

/* puzzle.txt  (1 string separated by " ")
index fen
0 1
1 r4rk1/p2np1b1/2ppbnpp/qp3p2/2PP4/P1NBPN1P/1P2QPPB/R3K2R
2 r4rk1/p2np1b1/2ppby1pp/qp3p2/2PPyn3/P1NBPN1P/1P2QPPB/R3K2R
3 r4rk1/p2np1b1/2ppb1pp/qp3p2/2PPn3/P1NBPN1P/1P2QPPB/y11yR1K2R
4 r4rk1/p2y1p1b1/1ynppb1pp/qp3p2/2PPn3/P1NBPN1P/1P2QPPB/2R1K2R
5 r4rk1/p3p1b1/1nppb1pp/qyP3p2/2y1Pn3/P1NBPN1P/1P2QPPB/2R1K2R
6 r4rk1/p3p1b1/1nppb1pp/qP3p2/3Py13/P1ynBPN1P/1P2QPPB/2R1K2R
7 r4rk1/p3p1b1/1nppb1pp/qP3p2/3P4/P1yPBPN1P/1y12QPPB/2R1K2R
8 r4rk1/p3p1b1/1ny1pb1pp/qyp3p2/3P4/P1PBPN1P/4QPPB/2R1K2R
9 1
10 r4rk1/p2np1b1/2ppbnpp/qp3p2/2PP4/P1NBPN1P/1P2QPPB/R3K2R
11 r4rk1/p2np1b1/2ppbnpp/qy13p2/1ypPP4/P1NBPN1P/1P2QPPB/R3K2R
12 r4rk1/p2np1b1/2ppbnpp/q4p2/1pPP4/P1y1BPN1P/yNP2QPPB/R3K2R
13 r4rk1/p2np1b1/2ppbnpp/q4p2/1y1PP4/Pyp1BPN1P/NP2QPPB/R3K2R
14 r4rk1/p2np1b1/2ppbnpp/q4p2/2PP4/PpyNBPN1P/y1P2QPPB/R3K2R
15 r4rk1/p2npybb1/2ppy1npp/q4p2/2PP4/PpNBPN1P/1P2QPPB/R3K2R
16 r4rk1/p2npbb1/2pp1npp/q4p2/2PP4/PpNBPy11P/1P1yNQPPB/R3K2R
17 r4rk1/p2ny1bb1/2pp1npp/q3ypp2/2PP4/PpNBP2P/1P1NQPPB/R3K2R
18 1
19 r4rk1/p2np1b1/2ppbnpp/qp3p2/2PP4/P1NBPN1P/1P2QPPB/R3K2R
20 r4rk1/p2np1b1/2ppbnpp/qy13p2/2ypP4/P1NBPN1P/1P2QPPB/R3K2R
21 r4rk1/p2np1b1/2ppbnpp/q4p2/2yBP4/P1Ny1PN1P/1P2QPPB/R3K2R
22 r4rk1/p2np1b1/2ppy1npp/q4p2/2ybP4/P1N1PN1P/1P2QPPB/R3K2R
23 r4rk1/p2np1b1/2pp1npp/q4p2/2yQP4/P1N1PN1P/1P2y1PPB/R3K2R
24 r4rk1/p2np1b1/2pp1y1pp/q2yn1p2/2QP4/P1N1PN1P/1P3PPB/R3K2R
25 r4rk1/p2np1b1/2pp2pp/q2n1p2/2QP4/P1N1PN1P/1P3PPB/R3y1RyK1
26 r4rk1/p2y1p1b1/1ynpp2pp/q2n1p2/2QP4/P1N1PN1P/1P3PPB/R4RK1
27 2
...
*/

const startFen = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR";
const numLinesRandom = 1000000; // gold
const numLinesPuzzle = 100000; // puzzle
const baseline1 = 1; // refer to 'puzzle.txt' above
const baseline2 = 10;
const baseline3 = 19;

let log = false;
let P;
let line1 = baseline1;
let line2 = baseline2;
let line3 = baseline3;
let activeLine = 0;

let part;
let randomId;
let puzzleFen = startFen;
let oldFenPuzzle = startFen;
let isHome = true;

let showNewButton = true;
let showIdButton = true;
let showPuzzleButton = true;

let showNewBoard = true;
let showKeypadBoard = false;
let showPuzzleBoard = false;
let showCopy = false;

function resetPuzzle() {
  // refer to 'puzzle.txt' above
  line1 = baseline1;
  line2 = baseline2;
  line3 = baseline3;
  activeLine = 0;
}

function App() {
  const baseURL_random =
    "https://us-east1-midgamechess.cloudfunctions.net/random";
  const baseURL_puzzle =
    "https://us-east1-midgamechess.cloudfunctions.net/puzzle";
  const [render, setRender] = useState("1");
  const [idNew, setIdNew] = useState(0);
  const [fenNew, setFenNew] = useState(startFen);
  const [idKeypad, setIdKeypad] = useState(0);
  const [fenKeypad, setFenKeypad] = useState(startFen);
  const [idPuzzle, setIdPuzzle] = useState(0);
  const [fenPuzzle, setFenPuzzle] = useState(startFen);
  const [renderNew, setRenderNew] = useState(true);
  const [renderKeypad, setRenderKeypad] = useState(false);

  const { height, width } = useWindowDimensions();

  if (log) {
     console.log("height = " + height);
     console.log("width = " + width);
  }

  let w;
  if (width / 2.0 > 0.78 * height) {
    w =  height/16 - 9; //1rem = 16 px, 8rem=header+pads
  } else {
    w = width / 32.0; //half the window width rem
  }
 
  let buttonsWidth = (0.20 * (width/16 - w)).toString() + 'rem';
  let helpWidth = (0.80 * (width/16 - w)).toString() + 'rem';
  let boardContainer = w.toString() + 'rem';
  let boardSize = (w-2).toString() + 'rem';
  let squareSize = ((w-2)/8).toString() + 'rem';
  let keySize = (0.06 * (width/16 - w)).toString() + 'rem';

  // font and button sizes are based on laptop full screen: 1500x750 pixels
  let fontSize;
  if ((width / height) > 2) {
    fontSize = (height / 375).toString() + 'rem'; // 2rem if height = 750px
  } else {
    fontSize = (width / 750).toString() + 'rem'; // 2rem if width = 1500px
  }

  let btnSize;
  if ((width / height) > 2) {
    btnSize = height / 750; // 1rem if height = 750px
  } else {
    btnSize = width / 1500; // 1rem if width = 1500px
  }

  if (log) {
    console.log('fontSize = ' + fontSize);
    console.log('boardContainer = ' + boardContainer);
    console.log('boardSize = ' + boardSize);
    console.log('squareSize = ' + squareSize);
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

  // press 'New' button and get back [id, fen] from server
  function getNewHandler() {
    if (log) {console.log("getNewHandler")}
    setRender("1");
    resetPuzzle();
    showNewButton = true;
    showIdButton = true;
    showPuzzleButton = true;

    showNewBoard = true
    showKeypadBoard = false;
    showPuzzleBoard = false;
    showCopy = false;

    if (!isHome) {
      if (log) {console.log("!isHome")}
      isHome = true;
      setIdNew(idNew);
      setFenNew(fenNew);
    } else {
      randomId = Math.floor(Math.random() * numLinesRandom) + 1; // random int 1..1000000
      fetch(baseURL_random + "?id=" + randomId)
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          if (log) {console.log("getNewHandler data= " + data)}
          part = data.split(" ");
          setIdNew(part[0]);
          setFenNew(part[1]);
          if (log) {console.log("getNewHandler id= " + part[0])}
          if (log) {console.log("getNewHandler fen= " + part[1])}
        });
    }
  }

  function copyHandler() {
    console.log("TRUE  showCopy showCopy showCopy");
    if (log) {console.log("copyHandler")}
    if (showNewBoard) {
      navigator.clipboard.writeText(fenNew);
    }
    if (showPuzzleBoard) {
      navigator.clipboard.writeText(P[1]);
      //navigator.clipboard.writeText(fenPuzzle);
    } 
    
    showCopy = true; 
    setRender("5");
  }
  
  // if 'ID' button is presed, show the keypad
  const getIdHandler = (event) => {
    resetPuzzle();
    isHome = false;
    showNewButton = false;
    showIdButton = false;
    showPuzzleButton = false;

    showNewBoard = false;
    showKeypadBoard = true;
    showPuzzleBoard = false;
    showCopy = false;

    if (log) {console.log("showKeypadBoard=" + showKeypadBoard)}
    setIdKeypad("");
    setFenKeypad(startFen);
    setRenderKeypad(true);
    setRender("2");
  };

  // get [id fen] from server
  const getKeypadCallbackHandler = (id) => {
    if (log) {console.log("getKeypadCallbackHandler: baseURL_random?id=" + String(id))}
    if (log) {console.log("render=" + render)}
    setRenderKeypad(false);
    showNewButton = true;
    showIdButton = true;
    showPuzzleButton = true;

    showNewBoard = true;
    showKeypadBoard = false;
    showPuzzleBoard = false;
    showCopy = false;

    isHome = true;
    if (id < 0) {
      // Cancel
    } else if (id===0) {
      setIdNew('0');
      setFenNew(startFen);
    } else {
      //OK
      if (log) {console.log("OK")}
      fetch(baseURL_random + "?id=" + String(id))
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          if (log) {console.log("getKeypadCallbackHandler data= " + data)}
          part = data.split(" ");
          setIdNew(part[0]);
          setFenNew(part[1]);
          if (log) {console.log("getKeypadCallbackHandler id= " + part[0])}
          if (log) {console.log("getKeypadCallbackHandler fen= " + part[1])}
        });
    }
    setRenderNew(true);
  };

  // press 'Get Puzzle' button and get back [27 lines] from server
  function getPuzzleHandler() {
    if (log) {console.log("getPuzzleHandler")}
    console.log("FALSE  showCopy showCopy showCopy");
    showCopy = false;
    resetPuzzle();
    isHome = false;
    if (!showPuzzleBoard && idPuzzle !== 0) {
      if (log) {console.log("i got 111111111")}
      setRender("4");
      showNewBoard = false;
      showKeypadBoard = false;
      showPuzzleBoard = true;

      setFenPuzzle(oldFenPuzzle);
    } else {
      if (log) {console.log("i got 22222222")}
      showNewBoard = false;
      showKeypadBoard = false;
      showPuzzleBoard = true;

      setRender("3");
      isHome = false;

      //get puzzle lines
      randomId = Math.floor(Math.random() * numLinesPuzzle) + 1;
      if (log) {console.log(baseURL_puzzle + "?id=" + String(randomId))}
      fetch(baseURL_puzzle + "?id=" + String(randomId))
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          P = data.split(" ");
          if (log) {console.log("P[1]=" + P[1])}
          if (log) {console.log("puzzleFen=" + puzzleFen)}
          setIdPuzzle(P[0]);
          setFenPuzzle(P[1]);
          oldFenPuzzle = P[1];
        });
    }
  }

  const rightHandler1 = (event) => {
    if (log) {console.log("rightHandler1")}
    if (log) {console.log("activeLine " + activeLine)}
    showCopy = false;
    //showPuzzleBoard = true;
    console.log("FALSE  showCopy showCopy showCopy");
    //console.log("TRUE showPuzzleBoard showPuzzleBoard showPuzzleBoard");

    if (activeLine === 0) {
      resetPuzzle();
      activeLine = 1;
      line1++;
    } else {
      if (activeLine === 1) {
        if (line1 < 8) {
          line1++;
        }
      } else {
        //another line was active
        resetPuzzle();
        activeLine = 1;
      }
    }
    if (log) {console.log("line1 = " + line1)}
    if (log) {console.log("fen1 " + P[line1])}
    setFenPuzzle(P[line1]);
  };
  const leftHandler1 = (event) => {
    if (log) {console.log("leftHandler1")}
    showCopy = false;
    if (line1 > baseline1 && activeLine === 1) {
      line1--;
      if (log) {console.log("line1 = " + line1)}
      if (log) {console.log("fen1 " + P[line1])}
      line2 = baseline2;
      line3 = baseline3;
    }
    else {
      resetPuzzle();
      activeLine = 1;
    }
    setFenPuzzle(P[line1]);
  };

  const rightHandler2 = (event) => {
    if (log) {console.log("rightHandler2")}
    if (log) {console.log("activeLine " + activeLine)}
    showCopy = false;

    if (activeLine === 0) {
      resetPuzzle();
      activeLine = 2;
      line2++;
    } else {
      if (activeLine === 2) {
        if (line2 < 17) {
          line2++;
        }
      } else {
        //another line was active
        resetPuzzle();
        activeLine = 2;
      }
    }
    if (log) {console.log("line2 = " + line2)}
    if (log) {console.log("fen2 " + P[line2])}
    setFenPuzzle(P[line2]);
  };
  const leftHandler2 = (event) => {
    if (log) {console.log("leftHandler2")}
    showCopy = false;
    if (line2 > baseline2 && activeLine === 2) {
      line2--;
      if (log) {console.log("line2 = " + line2)}
      if (log) {console.log("fen2 " + P[line2])}
    }
    else {
      resetPuzzle();
      activeLine = 2;
    }
    setFenPuzzle(P[line2]);
  };

  const rightHandler3 = (event) => {
    if (log) {console.log("rightHandler3")}
    if (log) {console.log("activeLine " + activeLine)}
    showCopy = false;

    if (activeLine === 0) {
      resetPuzzle();
      activeLine = 3;
      line3++;
    } else {
      if (activeLine === 3) {
        if (line3 < 26) {
          line3++;
        }
      } else {
        //another line was active
        resetPuzzle();
        activeLine = 3;
      }
    }
    if (log) {console.log("line3 = " + line3)}
    if (log) {console.log("fen3 " + P[line3])}
    setFenPuzzle(P[line3]);
  };
  const leftHandler3 = (event) => {
    if (log) {console.log("leftHandler3")}
    showCopy = false;
    if (line3 > baseline3 && activeLine === 3) {
      line3--;
      if (log) {console.log("line3 = " + line3)}
      if (log) {console.log("fen3 " + P[line3])}
    }
    else {
      resetPuzzle();
      activeLine = 3;
    }
    setFenPuzzle(P[line3]);
  };

  return (
  <div class='appContainer'>
    <div class="column">
      
      <div>
        <header class="header">Mid Game Chess</header>
      </div>

      <div style={{
          "height" : '0.5rem',
          "background-color" : "white",
          }}>
      </div>

      <div class="row">
        <div style={{
          "width" : '0.5rem',
          "background-color" : "white",
          }}>
        </div>

        {showNewBoard && renderNew && (
          <div>
            <Board 
              id={idNew} 
              fen={fenNew}
              boardContainer={boardContainer}
              boardSize={boardSize}
              squareSize={squareSize}>                
            </Board>
          </div>
        )}

        {showKeypadBoard && (
          <div>
            <Board 
              id={idKeypad} 
              fen={fenKeypad}
              boardContainer={boardContainer}
              boardSize={boardSize}
              squareSize={squareSize}
            ></Board>
          </div>
        )}

        {showPuzzleBoard && (
          <div>
            <Board 
              id={idPuzzle} 
              fen={fenPuzzle}
              boardContainer={boardContainer}
              boardSize={boardSize}
              squareSize={squareSize}
            ></Board>
          </div>
        )}
        
        <div style={{
          "height" : boardContainer,
          "width" : buttonsWidth,
          "background-color" : "white",
          }}>
       
          <div class="column">
            {showNewBoard && 
              <div style={{
                "color": "black",
                "font": "bold",
                "font-size": fontSize,
                "marginLeft": "1rem"
                }}>ID: {idNew}
              </div>}

            <div class="row">                
              {showNewButton && (
                <Btn btnSize={btnSize} handler={getNewHandler} text='New'></Btn>
              )}
              {showNewButton && (
                <Btn btnSize={btnSize} handler={copyHandler} text='Copy'></Btn>
              )}
            </div>
            {showIdButton && (
              <Btn btnSize={btnSize} handler={getIdHandler} text='ID'></Btn>
            )}
            {showPuzzleButton && (
              <Btn btnSize={btnSize} handler={getPuzzleHandler} text='Puzzle'></Btn>
            )}
          
            <div class="row">
              {showPuzzleBoard && (
                <Btn btnSize={btnSize} handler={rightHandler1} text='1 >>'></Btn>
              )}
              {showPuzzleBoard && (
                <Btn btnSize={btnSize} handler={leftHandler1} text=' <<'></Btn>
              )}
            </div>

            <div class="row">
              {showPuzzleBoard && (
                <Btn btnSize={btnSize} handler={rightHandler2} text='2 >>'></Btn>
              )}
              {showPuzzleBoard && (
                <Btn btnSize={btnSize} handler={leftHandler2} text=' <<'></Btn>
              )}
            </div>

            <div class="row">
              {showPuzzleBoard && (
                <Btn btnSize={btnSize} handler={rightHandler3} text='3 >>'></Btn>
              )}
              {showPuzzleBoard && (
                <Btn btnSize={btnSize} handler={leftHandler3} text=' <<'></Btn>
              )}
            </div>

            {showKeypadBoard && renderKeypad && (
              <Keypad 
                btnSize={btnSize} 
                keySize={keySize} 
                fontSize={fontSize}
                id={idKeypad} 
                parentCallback={getKeypadCallbackHandler} 
              />
            )}
          </div>

        </div>

        <div style={{
          "height" : boardContainer,
          "width" : helpWidth,
          }}>
          {showNewBoard && !showCopy && (
            <div style={{
              "background-color" : "white",
              "color" : "black",
              "font" : "bold",
              "font-family" : "'Times New Roman', Times, serif",
              "font-size" : fontSize,
              "padding" : "1rem",
              }}>
              <p>Get a Mid Game from a collection of 1 million!</p>
              <p>
                The position is a{" "}
                <span style={{"color" : "red"}}>
                  Completely New Game</span> - arrived at by
                  a sequence of moves not in the database
              </p>
              <p>Set up your board and play the same game twice</p>
              <p>Once as white, then as black</p>
              <p>White to move</p>
              <p></p>
              <p>phone app: midgamechess</p>
            </div>
          )}

          {showKeypadBoard && (
            <div style={{
              "background-color" : "white",
              "color" : "black",
              "font" : "bold",
              "font-family" : "'Times New Roman', Times, serif",
              "font-size" : fontSize,
              "padding" : "1rem",
            }}>
              <p>Retrieve a Mid Game from an ID</p>
            </div>
          )} 

          {showPuzzleBoard && !showCopy && (
            <div style={{
              "background-color" : "white",
              "color" : "black",
              "font" : "bold",
              "font-family" : "'Times New Roman', Times, serif",
              "font-size" : fontSize,
              "padding" : "1rem",
              }}>
              <p>Can you find the best move?</p>
              <p>According to the chess engine 1, 2 and 3 are the best moves </p>
              <p>White to move</p>
              <p></p>
              <p>phone app: midgamechess</p>
            </div>
          )}  

          {showCopy && (
            <div style={{
              "background-color" : "white",
              "color" : "black",
              "font" : "bold",
              "font-family" : "'Times New Roman', Times, serif",
              "font-size" : fontSize,
              "padding" : "1rem",
              }}>
              <p>The FEN position has been copied to the clipboard</p>
              <p></p>
              <p>Paste the FEN into another chess program to analyze or play</p>
              <p></p>
              <p>In Chess.com paste the FEN into: Learn/Analysis/Load From FEN : Add game</p>
            </div>
          )} 
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default App;
