function Key(props) {
  return (
    <img src={props.png} alt='?'
      style={{
        "object-fit" : "fill",
        "height" : props.keySize,
        "width" : props.keySize,
      }}>
    </img>
  );
}

export default Key;
