function Square(props) {
  return ( 
    <img src={props.png} alt='?'
      style={{
        "object-fit" : "fill",
        "height" : props.squareSize,
        "width" : props.squareSize,
      }}>
    </img>
  );
}

export default Square;
