import React, { useState } from "react";
import Key from "./Key";
import Btn from "./Btn";

import key0 from "../assets/images/key0.png";
import key1 from "../assets/images/key1.png";
import key2 from "../assets/images/key2.png";
import key3 from "../assets/images/key3.png";
import key4 from "../assets/images/key4.png";
import key5 from "../assets/images/key5.png";
import key6 from "../assets/images/key6.png";
import key7 from "../assets/images/key7.png";
import key8 from "../assets/images/key8.png";
import key9 from "../assets/images/key9.png";
import keyArrow from "../assets/images/keyArrow.png";
import keyClr from "../assets/images/keyClr.png";

function Keypad(props) {
  const pngs = [
    { png: key1 }, //[0]
    { png: key2 }, //[1]
    { png: key3 }, //[2]

    { png: key4 }, //[3]
    { png: key5 }, //[4]
    { png: key6 }, //[5]

    { png: key7 }, //[6]
    { png: key8 }, //[7]
    { png: key9 }, //[8]

    { png: key0 }, //[9
    { png: keyClr }, //[10]
    { png: keyArrow }, //[11]
  ];

  let log = false; //enable console.log() ?
  const [id, setId] = useState("");
  const [isShown, setIsShown] = useState(true);
  const cancel = -1;

  const clickHandler1 = () => {
    setId(id + "1");
    if (log) {console.log(id)}
  };
  const clickHandler2 = () => {
    setId(id + "2");
    if (log) {console.log(id)}
  };
  const clickHandler3 = () => {
    setId(id + "3");
    if (log) {console.log(id)}
  };
  const clickHandler4 = () => {
    setId(id + "4");
    if (log) {console.log(id)}
  };
  const clickHandler5 = () => {
    setId(id + "5");
    if (log) {console.log(id)}
  };
  const clickHandler6 = () => {
    setId(id + "6");
    if (log) {console.log(id)}
  };
  const clickHandler7 = () => {
    setId(id + "7");
    if (log) {console.log(id)}
  };
  const clickHandler8 = () => {
    setId(id + "8");
    if (log) {console.log(id)}
  };
  const clickHandler9 = () => {
    setId(id + "9");
    if (log) {console.log(id)}
  };
  const clickHandler0 = () => {
    setId(id + "0");
    if (log) {console.log(id)}
  };
  const clickHandlerClr = () => {
    setId("");
    if (log) {console.log(id)}
  };
  const clickHandlerArrow = () => {
    setId(id.slice(0, id.length - 1));
    if (log) {console.log(id)}
  };

  const handleOkClick = (event) => {
    if (log) {console.log("keypad is returning id= " + id)}
    if ((id === "") || (Number(id) > 1000000)) {
      props.parentCallback(cancel);
    } else {
      props.parentCallback(Number(id));
    }
    setIsShown((current) => false);
  };

  const handleCancelClick = () => {
    if (log) {console.log("Keypad id=" + id)}
    if (log) {console.log("props.id=" + cancel)}
    props.parentCallback(cancel);
    setIsShown((current) => false);
  };

  return (
    <>
      <div>
        {isShown && (
          <div class="column" style={{"height" : props.keySize}}>
            <div style={{
              "color": "black",
              "font": "bold",
              "font-size": props.fontSize,
              "marginLeft": "0.5rem"
              }}>ID: {id}
            </div>

            <div class="row" style={{"height" : props.keySize, "marginLeft":"0.5rem"}}>
              <div onClick={clickHandler1}>
                <Key keySize={props.keySize} png={pngs[0].png}></Key>
              </div>
              <div onClick={clickHandler2}>
                <Key keySize={props.keySize} png={pngs[1].png}></Key>
              </div>
              <div onClick={clickHandler3}>
                <Key keySize={props.keySize} png={pngs[2].png}></Key>
              </div>
            </div>

            <div class="row" style={{"height" : props.keySize, "marginLeft":"0.5rem"}}>
              <div onClick={clickHandler4}>
                <Key keySize={props.keySize} png={pngs[3].png}></Key>
              </div>
              <div onClick={clickHandler5}>
                <Key keySize={props.keySize} png={pngs[4].png}></Key>
              </div>
              <div onClick={clickHandler6}>
                <Key keySize={props.keySize} png={pngs[5].png}></Key>
              </div>
            </div>

            <div class="row" style={{"height" : props.keySize, "marginLeft":"0.5rem"}}>
              <div onClick={clickHandler7}>
                <Key keySize={props.keySize} png={pngs[6].png}></Key>
              </div>
              <div onClick={clickHandler8}>
                <Key keySize={props.keySize} png={pngs[7].png}></Key>
              </div>
              <div onClick={clickHandler9}>
                <Key keySize={props.keySize} png={pngs[8].png}></Key>
              </div>
            </div>

            <div class="row" style={{"height" : props.keySize, "marginLeft":"0.5rem"}}>
              <div onClick={clickHandler0}>
                <Key keySize={props.keySize} png={pngs[9].png}></Key>
              </div>
              <div onClick={clickHandlerClr}>
                <Key keySize={props.keySize} png={pngs[10].png}></Key>
              </div>
              <div onClick={clickHandlerArrow}>
                <Key keySize={props.keySize} png={pngs[11].png}></Key>
              </div>
            </div>

            <div class="row" 
              style={{
                "height" : props.keySize, 
                "marginTop" : "1rem", 
                }}>
              <div>
                <Btn btnSize={props.btnSize} handler={handleOkClick} text='OK'></Btn>
              </div>
              <div>
                <Btn btnSize={props.btnSize} handler={handleCancelClick} text='Cancel'></Btn>
              </div>
            </div>

          </div>
        )}
      </div>
    </>
  );
}

export default Keypad;
