import Square from "./Square";

import wr_on_white from '../assets/images/wr_on_white.png';
import wn_on_white from '../assets/images/wn_on_white.png';
import wb_on_white from '../assets/images/wb_on_white.png';
import wq_on_white from '../assets/images/wq_on_white.png';
import wk_on_white from '../assets/images/wk_on_white.png';
import wp_on_white from '../assets/images/wp_on_white.png';

import wr_on_black from '../assets/images/wr_on_black.png';
import wn_on_black from '../assets/images/wn_on_black.png';
import wb_on_black from '../assets/images/wb_on_black.png';
import wq_on_black from '../assets/images/wq_on_black.png';
import wk_on_black from '../assets/images/wk_on_black.png';
import wp_on_black from '../assets/images/wp_on_black.png';

import br_on_white from '../assets/images/br_on_white.png';
import bn_on_white from '../assets/images/bn_on_white.png';
import bb_on_white from '../assets/images/bb_on_white.png';
import bq_on_white from '../assets/images/bq_on_white.png';
import bk_on_white from '../assets/images/bk_on_white.png';
import bp_on_white from '../assets/images/bp_on_white.png';

import br_on_black from '../assets/images/br_on_black.png';
import bn_on_black from '../assets/images/bn_on_black.png';
import bb_on_black from '../assets/images/bb_on_black.png';
import bq_on_black from '../assets/images/bq_on_black.png';
import bk_on_black from '../assets/images/bk_on_black.png';
import bp_on_black from '../assets/images/bp_on_black.png';

import wr_on_yellow from '../assets/images/wr_on_yellow.png';
import wn_on_yellow from '../assets/images/wn_on_yellow.png';
import wb_on_yellow from '../assets/images/wb_on_yellow.png';
import wq_on_yellow from '../assets/images/wq_on_yellow.png';
import wk_on_yellow from '../assets/images/wk_on_yellow.png';
import wp_on_yellow from '../assets/images/wp_on_yellow.png';

import br_on_yellow from '../assets/images/br_on_yellow.png';
import bn_on_yellow from '../assets/images/bn_on_yellow.png';
import bb_on_yellow from '../assets/images/bb_on_yellow.png';
import bq_on_yellow from '../assets/images/bq_on_yellow.png';
import bk_on_yellow from '../assets/images/bk_on_yellow.png';
import bp_on_yellow from '../assets/images/bp_on_yellow.png';

import white from '../assets/images/white.png';
import black from '../assets/images/black.png';
import gold from '../assets/images/gold.png';

function Board(props) {
  let log = false;
  if (log) {
    console.log('boardContainer = ' + props.boardContainer);
    console.log('boardSize = ' + props.boardSize);
    console.log('squareSize = ' + props.squareSize);
  }
  const pngs = [
    { png: wr_on_white }, //[0]
    { png: wn_on_white }, //[]
    { png: wb_on_white }, //[]
    { png: wq_on_white }, //[]
    { png: wk_on_white }, //[]
    { png: wp_on_white }, //[]
  
    { png: wr_on_black }, //[6]
    { png: wn_on_black }, //[]
    { png: wb_on_black }, //[]
    { png: wq_on_black }, //[]
    { png: wk_on_black }, //[]
    { png: wp_on_black }, //[]
  
    { png: br_on_white }, //[12]
    { png: bn_on_white }, //[]
    { png: bb_on_white }, //[]
    { png: bq_on_white }, //[]
    { png: bk_on_white }, //[]
    { png: bp_on_white }, //[]
  
    { png: br_on_black }, //[18]
    { png: bn_on_black }, //[]
    { png: bb_on_black }, //[]
    { png: bq_on_black }, //[]
    { png: bk_on_black }, //[]
    { png: bp_on_black }, //[]
  
    { png: wr_on_yellow }, //[24]
    { png: wn_on_yellow }, //[]
    { png: wb_on_yellow }, //[]
    { png: wq_on_yellow }, //[]
    { png: wk_on_yellow }, //[]
    { png: wp_on_yellow }, //[]
  
    { png: wr_on_yellow }, //[30]   repeated to make offset easier
    { png: wn_on_yellow }, //[]
    { png: wb_on_yellow }, //[]
    { png: wq_on_yellow }, //[]
    { png: wk_on_yellow }, //[]
    { png: wp_on_yellow }, //[]
  
    { png: br_on_yellow }, //[36]
    { png: bn_on_yellow }, //[]
    { png: bb_on_yellow }, //[]
    { png: bq_on_yellow }, //[]
    { png: bk_on_yellow }, //[]
    { png: bp_on_yellow }, //[]
  
    { png: br_on_yellow }, //[42]   repeated to make offset easier
    { png: bn_on_yellow }, //[]
    { png: bb_on_yellow }, //[]
    { png: bq_on_yellow }, //[]
    { png: bk_on_yellow }, //[]
    { png: bp_on_yellow }, //[]
  
    { png: white }, //[48]
    { png: black }, //[49]
    { png: gold }, //[50]
  ];

  const m = new Map([
    ["R", 0],
    ["N", 1],
    ["B", 2],
    ["Q", 3],
    ["K", 4],
    ["P", 5],
    ["r", 12],
    ["n", 13],
    ["b", 14],
    ["q", 15],
    ["k", 16],
    ["p", 17],
  ]);

  var white_to_black_offset = 6;
  var yellow_offset = 24; // add this to make the square background yellow
  var white_square = 48;
  var black_square = 49;
  var gold_square = 50;
  var isYellow = false;
  var isGoldSquare = false;
  var isWhite = true;
  var asciiVal;
  var n = 0;
  var num;
  
  const indx = []; // index into array of .png images

  var fen = props.fen;

  for (var ch of fen) {
    if (ch === "/") {
      isWhite = !isWhite; // the color on end of col changes on start of the next row
      continue; // end of row
    }
    if (ch === "g") {
      isGoldSquare = true;
      //continue; // get ch following the 'g'
    }
    if (ch === "y") {
      isYellow = true;
      continue; // get ch following the 'y'
    }
    //pgb2by2p
    asciiVal = ch.charCodeAt(0);
    if (asciiVal >= 49 && asciiVal <= 56) {
      //1..8
      num = asciiVal - 48;
      for (var j = 0; j < num; j++) {
        if (isWhite) {
          indx[n] = white_square; // empty white square
        } else {
          indx[n] = black_square; // empty black square
        }
        if (isGoldSquare) {
          indx[n] = gold_square;
          isGoldSquare = false;
        }
        n++;
        isWhite = !isWhite;
      }
    } else {
      indx[n] = m.get(ch);
      if (!isWhite) {
        indx[n] += white_to_black_offset; //white -> black
      }
      if (isYellow) {
        indx[n] += yellow_offset; //yellow background
        isYellow = false;
      }
      if (isGoldSquare) {
        indx[n] = gold_square;
        isGoldSquare = false;
      }
      n++;
      isWhite = !isWhite;
    }
  }

  // the sequence of the index[] in the following is not important
  return (
    <div style={{
      "height" : props.boardContainer,
      "width" : props.boardContainer,
      "background-color" : "black"
      }}>
  
      <div style={{
        "height" : props.boardSize, 
        "width" : props.boardSize,
        "background-color" : "black",
        "padding" : '1rem',
        }}>

        <div class="column" style={{"height" : props.squareSize}}>

          <div class="row" style={{"height" : props.squareSize}}>
            <Square png={pngs[indx[0]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[1]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[2]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[3]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[4]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[5]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[6]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[7]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>
            <Square png={pngs[indx[8]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[9]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[10]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[11]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[12]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[13]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[14]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[15]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>
            <Square png={pngs[indx[16]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[17]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[18]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[19]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[20]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[21]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[22]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[23]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>
            <Square png={pngs[indx[24]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[25]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[26]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[27]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[28]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[29]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[30]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[31]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>
            <Square png={pngs[indx[32]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[33]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[34]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[35]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[36]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[37]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[38]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[39]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>
            <Square png={pngs[indx[40]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[41]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[42]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[43]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[44]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[45]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[46]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[47]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>  
            <Square png={pngs[indx[48]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[49]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[50]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[51]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[52]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[53]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[54]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[55]].png} squareSize={props.squareSize}></Square>
          </div>

          <div class="row" style={{"height" : props.squareSize}}>  
            <Square png={pngs[indx[56]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[57]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[58]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[59]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[60]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[61]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[62]].png} squareSize={props.squareSize}></Square>
            <Square png={pngs[indx[63]].png} squareSize={props.squareSize}></Square>
          </div>

        </div>
      </div>
    </div>  
  );
}

export default Board;