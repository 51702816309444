import "./Btn.css";

function Btn(props) {
 
  // adjust button size based on screen size
  let size100 = false;
  let size90 = false;
  let size80 = false;
  let size70 = false;
  let size60 = false;
  let size50 = false;

  if (props.btnSize > 0.9) {
    size100 = true;
  } else if (props.btnSize > 0.8) {
    size90 = true;
  } else if (props.btnSize > 0.7) {
    size80 = true;
  } else if (props.btnSize > 0.6) {
    size70 = true;
  } else if (props.btnSize > 0.5) {
    size60 = true;
  } else {
    size50 = true;
  } 

  return ( 
    <> 
      {size100 && (
        <button class="btn100 btnHover"
          onClick={props.handler}>{props.text}
        </button>
      )} 

      {size90 && (
        <button class="btn90 btnHover"
          onClick={props.handler}>{props.text}
        </button>
      )}  

      {size80 && (
        <button class="btn80 btnHover"
          onClick={props.handler}>{props.text}
        </button>
      )} 

      {size70 && (
        <button class="btn70 btnHover"
          onClick={props.handler}>{props.text}
        </button>
      )}

      {size60 && (
        <button class="btn60 btnHover"
          onClick={props.handler}>{props.text}
        </button>
      )} 

      {size50 && (
        <button class="btn50 btnHover"
          onClick={props.handler}>{props.text}
        </button>
      )}  

    </>    
  );
}
   
export default Btn;
